.aw {
  --theme-body: theme('colors.dark-gray');
  --theme-btn-bg: theme('colors.white');
  --theme-btn-bg-hover: theme('colors.primary');
  --theme-btn-text: theme('colors.secondary');
  --theme-btn-text-hover: theme('colors.white');
  --theme-btn-decoration: theme('colors.black');
  --theme-btn-border: theme('colors.primary');
  --theme-btn-border-hover: theme('colors.primary');
}

.rba {
  --theme-body: theme('colors.dark-gray');
  --theme-btn-bg: theme('colors.secondary');
  --theme-btn-bg-hover: theme('colors.primary');
  --theme-btn-text: theme('colors.white');
  --theme-btn-text-hover: theme('colors.secondary');
  --theme-btn-decoration: theme('colors.black');
  --theme-btn-border: theme('colors.primary');
  --theme-btn-border-hover: theme('colors.primary');
  --theme-btn-icon: theme('colors.primary');
  --theme-btn-icon-hover: theme('colors.primary');
  --theme-btn-primary-icon: theme('colors.primary');
  --theme-btn-primary-icon-hover: theme('colors.secondary');
  --theme-btn-secondary-bg: theme('colors.white');
  --theme-btn-secondary-bg-hover: theme('colors.secondary');
  --theme-btn-secondary-text: theme('colors.black');
  --theme-btn-secondary-text-hover: theme('colors.white');
  --theme-btn-secondary-border: theme('colors.black');
  --theme-btn-secondary-border-hover: theme('colors.black');
}

.aw .theme-black {
  --theme-bg: theme('colors.secondary');
  --theme-text: theme('colors.white');
  --theme-body: theme('colors.white');
  --theme-btn-bg: theme('colors.white');
  --theme-btn-bg-hover: theme('colors.primary');
  --theme-btn-text: theme('colors.secondary');
  --theme-btn-text-hover: theme('colors.white');
  --theme-btn-decoration: theme('colors.white');
  --theme-btn-border: theme('colors.primary');
  --theme-btn-border-hover: theme('colors.secondary');
}

.rba .theme-black {
  --theme-bg: theme('colors.secondary');
  --theme-text: theme('colors.white');
  --theme-body: theme('colors.white');
  --theme-btn-bg: theme('colors.white');
  --theme-btn-bg-hover: theme('colors.primary');
  --theme-btn-text: theme('colors.secondary');
  --theme-btn-text-hover: theme('colors.secondary');
  --theme-btn-decoration: theme('colors.white');
  --theme-btn-border: theme('colors.secondary');
  --theme-btn-border-hover: theme('colors.secondary');
  --theme-btn-secondary-bg: theme('colors.secondary');
  --theme-btn-secondary-bg-hover: theme('colors.white');
  --theme-btn-secondary-text: theme('colors.white');
  --theme-btn-secondary-text-hover: theme('colors.black');
  --theme-btn-secondary-border: theme('colors.white');
  --theme-btn-secondary-border-hover: theme('colors.white');
}

.aw .theme-gray {
  --theme-bg: theme('colors.light-gray');
  --theme-text: theme('colors.secondary');
  --theme-body: theme('colors.dark-gray');
  --theme-btn-bg: theme('colors.white');
  --theme-btn-bg-hover: theme('colors.primary');
  --theme-btn-text: theme('colors.secondary');
  --theme-btn-text-hover: theme('colors.white');
  --theme-btn-decoration: theme('colors.black');
  --theme-btn-border: theme('colors.primary');
  --theme-btn-border-hover: theme('colors.primary');
}

.rba .theme-gray {
  --theme-bg: theme('colors.light-gray');
  --theme-text: theme('colors.secondary');
  --theme-body: theme('colors.dark-gray');
  --theme-btn-bg: theme('colors.secondary');
  --theme-btn-bg-hover: theme('colors.primary');
  --theme-btn-text: theme('colors.white');
  --theme-btn-decoration: theme('colors.black');
  --theme-btn-text-hover: theme('colors.secondary');
  --theme-btn-border: theme('colors.primary');
  --theme-btn-border-hover: theme('colors.primary');
}

.aw .theme-primary {
  --theme-bg: theme('colors.primary');
  --theme-text: theme('colors.white');
  --theme-body: theme('colors.white');
  --theme-btn-bg: theme('colors.white');
  --theme-btn-bg-hover: theme('colors.secondary');
  --theme-btn-text: theme('colors.secondary');
  --theme-btn-decoration: theme('colors.black');
  --theme-btn-text-hover: theme('colors.white');
  --theme-btn-border: theme('colors.white');
  --theme-btn-border-hover: theme('colors.secondary');
}

.rba .theme-primary {
  --theme-bg: theme('colors.primary');
  --theme-text: theme('colors.black');
  --theme-body: theme('colors.white');
  --theme-btn-bg: theme('colors.white');
  --theme-btn-bg-hover: theme('colors.secondary');
  --theme-btn-text: theme('colors.secondary');
  --theme-btn-text-hover: theme('colors.white');
  --theme-btn-decoration: theme('colors.black');
  --theme-btn-border: theme('colors.secondary');
  --theme-btn-border-hover: theme('colors.secondary');
  --theme-btn-icon: theme('colors.secondary');
  --theme-btn-icon-hover: theme('colors.secondary');
  --theme-btn-primary-icon: theme('colors.primary');
  --theme-btn-primary-icon-hover: theme('colors.primary');
}

.aw .theme-secondary {
  --theme-bg: theme('colors.black');
  --theme-text: theme('colors.white');
  --theme-body: theme('colors.white');
  --theme-btn-bg: theme('colors.white');
  --theme-btn-bg-hover: theme('colors.primary');
  --theme-btn-text: theme('colors.secondary');
  --theme-btn-text-hover: theme('colors.white');
  --theme-btn-decoration: theme('colors.black');
  --theme-btn-border: theme('colors.white');
  --theme-btn-border-hover: theme('colors.primary');
}

.rba .theme-secondary {
  --theme-bg: theme('colors.secondary');
  --theme-text: theme('colors.white');
  --theme-body: theme('colors.white');
  --theme-btn-bg: theme('colors.white');
  --theme-btn-bg-hover: theme('colors.primary');
  --theme-btn-text: theme('colors.secondary');
  --theme-btn-text-hover: theme('colors.secondary');
  --theme-btn-decoration: theme('colors.black');
  --theme-btn-border: theme('colors.secondary');
  --theme-btn-border-hover: theme('colors.secondary');
  --theme-btn-secondary-bg: theme('colors.secondary');
  --theme-btn-secondary-bg-hover: theme('colors.white');
  --theme-btn-secondary-text: theme('colors.white');
  --theme-btn-secondary-text-hover: theme('colors.black');
  --theme-btn-secondary-border: theme('colors.white');
  --theme-btn-secondary-border-hover: theme('colors.white');
}

.rba .theme-white {
  --theme-bg: theme('colors.white');
  --theme-text: theme('colors.secondary');
  --theme-body: theme('colors.dark-gray');
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-640px);
  }

  100% {
    transform: translateX(0);
  }
}

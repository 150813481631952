.reviewBar {
    justify-items: flex-start;

    .rc-progress-line {
        height: 3px;
        width: 29vw;

        @media (max-width: 640px) {
            width: 35vw;
        }
    }

    .rc-progress-line-trail {
        stroke-linecap: square;
    }

    .rc-progress-line-path {
        stroke: rgb(var(--colors-black));
        stroke-linecap: square;
    }
}

.ratingStars {
    gap: 3px;
}

.loadMoreIcon svg {
  display: unset;
}

div.rating-button {
  cursor: pointer;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox *
{
    box-sizing: border-box !important;

}

body:not(.bv-standalone-container) :not(.bv-mbox-inner).bv-mbox-lightbox,
.bv-cv2-cleanslate .bv-mbox-lightbox,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review.bv-mbox-box,
.bv-mbox-opened .bv-mbox-lightbox .bv-mbox-content-submission-review.bv-mbox-box .bv-mbox {
  box-sizing: border-box !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox {
  padding-bottom: 50px !important;
  padding-top: 3rem !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review.bv-mbox-narrow .bv-mbox {
  padding: 0 !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-wide .bv-mbox .bv-mbox-injection-target {
  left: 0 !important;
  right: 0 !important;
  position: relative !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-close {
  top: 10px !important;
  right: 10px !important;
}

/* Submission Sidebar (with image) */
#bv-mboxzone-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-sidebar .bv-submission-sidebar.bv-submission-side {
  margin: 0 theme('spacing.xxs') !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-sidebar .bv-subject-info {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  border-top: 3px solid #000 !important;
  padding-top: theme('spacing.xxs') !important;
  padding-bottom: theme('spacing.m') !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-sidebar .bv-submission-sidebar .bv-subject-info-section .bv-subject-info span {
  order: -1;
  font-family: theme('fontFamily.serif') !important;
  font-weight: theme('fontWeight.bold') !important;
  font-size: theme('fontSize.body.0') !important;
  line-height: theme('fontSize.body.1') !important;
  margin-bottom: theme('spacing.xxs') !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-sidebar .bv-subject-image {
  width: 140px !important;
  max-width: 140px !important;
  height: auto !important;
  max-height: none !important;
}

/* Breadcrumb */
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-breadcrumb {
    padding: 0 !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-breadcrumb span.bv-mbox-breadcrumb-item span {
  font-family: theme('fontFamily.sans') !important;
  font-weight: theme('fontWeight.heavy') !important;
  font-size: theme('fontSize.sm-s.0') !important;
  line-height: theme('fontSize.sm-s.1') !important;
  white-space: inherit !important;
}

/* Content container */
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets {
  background: none !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset {
  padding: 16px 0 !important;
  position: relative !important;
  width: 100% !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset.bv-error
{
    padding-top: 50px !important;
}

#bv-mboxzone-lightbox.bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset-rating .bv-fieldset-label {
  padding: 0 0.5em .6em 0 !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset.bv-form-actions {
  padding: 0 !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active {
  background: none !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset .bv-fieldset-arrowicon
{
    display: none !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-sidebar {
  width: 100% !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review.bv-mbox-wide .bv-mbox .bv-mbox-content-container {
  border-top: 1px solid theme('colors.gray') !important;
  padding-top: theme('spacing.s') !important;
  padding-left: 0.1px !important;
  padding-right: 0.1px !important;
  margin-left: theme('spacing.xxs') !important;
  margin-right: theme('spacing.xxs') !important;
  width: auto !important;
  max-width: 100% !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-required-fields-text {
  font-family: theme('fontFamily.serif') !important;
  font-weight: theme('fontWeight.regular') !important;
  font-size: 10px !important;
  line-height: 1rem !important;
  padding: 0 !important;
  margin: theme('spacing.s') 0 !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-isrecommended .bv-fieldset-label-wrapper .bv-fieldset-label,
#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset .bv-fieldset-inner .bv-fieldset-label-wrapper {
  padding: 0 !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-isrecommended .bv-fieldset-label-wrapper {
    padding: 0 !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset .bv-fieldset-inner {
  padding: 0 !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission #bv-fieldset-label-rating {
  padding: 0 !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-submission .bv-fieldsets .bv-fieldset .bv-fieldset-label .bv-fieldset-label-text {
  font-family: theme('fontFamily.sans') !important;
  font-weight: theme('fontWeight.heavy') !important;
  font-size: theme('fontSize.sm-xs.0') !important;
  line-height: theme('fontSize.sm-xs.1') !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-review-field-content-wrapper {
  border-radius: 0 !important;
  border-color: theme('colors.gray') !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets input.bv-text {
  border-radius: 0 !important;
  border-color: theme('colors.gray') !important;
  margin-top: theme('spacing.s') !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets textarea {
  border-bottom-color: theme('colors.gray') !important;
  margin-top: theme('spacing.s') !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets textarea,
#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets input.bv-text {
  font-family: theme('fontFamily.serif') !important;
  font-weight: theme('fontWeight.regular') !important;
  font-size: theme('fontSize.body.0') !important;
  line-height: theme('fontSize.body.1') !important;
  padding: theme('spacing.xs') theme('spacing.xxs') !important;
  margin-bottom: 0 !important;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-radio-field .bv-fieldset-radio-wrapper {
  width: 100% !important;
  padding-left: 0 !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset .bv-fieldset-rating-group {
  margin-top: 0 !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset .bv-radio-group {
  margin-bottom: 0 !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldset-radio-wrapper .bv-rating-input:first-child .bv-rating-link,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldset-radio-wrapper .bv-rating-input:last-child .bv-rating-link,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-submission-star-rating .bv-rating-link {
  border: none !important;
  border-radius: 0 !important;
  margin-right: theme('spacing.xxs') !important;
  height: 48px !important;
  width: 48px !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldset-radio-wrapper .bv-rating-input:last-child .bv-rating-link
{
    margin-right: 0 !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-submission-star-rating .bv-rating-link {
  background-color: theme('colors.gray') !important;
  padding: 9px !important;
  display: flex !important;
  align-items: center;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-rating-helper {
  font-family: theme('fontFamily.serif') !important;
  font-weight: theme('fontWeight.regular') !important;
  font-size: theme('fontSize.small.0') !important;
  line-height: theme('fontSize.small.1') !important;
  position: absolute !important;
  padding: 0 !important;
  top: 17px !important;
  left: 120px !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions {
    display: flex !important;
    flex-direction: row;
    align-items: center !important;
}

/* Buttons */
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn {
  border: 4px solid theme('colors.gray') !important;
  padding: 9px 20px !important;
  background: none !important;
  border-radius: 65px !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn:hover,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn:hover {
  background: theme('colors.gray') !important;
  color: theme('colors.white') !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn span {
  font-family: theme('fontFamily.sans') !important;
  font-weight: theme('fontWeight.heavy') !important;
  font-size: theme('fontSize.button.0') !important;
  line-height: theme('fontSize.button.1') !important;
  color: theme('colors.black') !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn:hover span,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn:active span
{
  color: theme('colors.white') !important;
}

  /* Recommended */
  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset-isrecommended .bv-fieldset-isrecommended-group {
    width: 100% !important;
    margin-top: 10px !important;
  }

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-fieldset-isrecommended-group ul {
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-isrecommended .bv-fieldset-isrecommended-group .bv-radio-container-li:first-child
{
    margin-right: 32px !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active:hover .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-isrecommended .bv-fieldset-isrecommended-group .bv-radio-container-li .bv-radio-wrapper-label {
  font-family: theme('fontFamily.sans') !important;
  font-weight: theme('fontWeight.heavy') !important;
  font-size: theme('fontSize.button.0') !important;
  line-height: theme('fontSize.button.1') !important;
  border: 4px solid theme('colors.gray') !important;
  padding: 9px 20px !important;
  background: none !important;
  border-radius: 65px !important;
  color: theme('colors.black') !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active:hover .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn:hover,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active:hover .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions .bv-content-btn:active,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-isrecommended .bv-fieldset-isrecommended-group .bv-radio-container-li .bv-radio-wrapper-label:hover,
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-isrecommended .bv-fieldset-isrecommended-group .bv-radio-container-li .bv-radio-wrapper-label:active {
  background: theme('colors.gray') !important;
  color: theme('colors.white') !important;
}

/* Nickname */
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset-usernickname.bv-fieldset-small {
    /*width: 100% !important;*/
}

/* User Location */
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset-userlocation.bv-fieldset-small {
  /*width: 100% !important;*/
}

/* User Type Select */
#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset.bv-fieldset-contextdatavalue_WhichOfTheFollowingBestDescribesYou .bv-fieldset-select-wrapper {
  float: none !important;
  width: 100% !important;
  display: inline-block !important;
  margin-top: theme('spacing.s') !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-select-field .bv-select {
  font-family: theme('fontFamily.serif') !important;
  font-weight: theme('fontWeight.regular') !important;
  font-size: theme('fontSize.body.0') !important;
  font-style: normal !important;
  line-height: theme('fontSize.body.1') !important;
  padding: theme('spacing.xs') theme('spacing.xxs') !important;
  height: auto !important;
  width: 100% !important;
  border-radius: 0 !important;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
  background-position: right 5px top; /* Change the px value here to change the distance */
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0 !important;
  left: 0px !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset .bv-helper {
  top: 14px !important;
  right: 0 !important;
  margin-top: 0 !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset .bv-helper-icon .bv-helper-icon-positive {
  bottom: 0 !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset .bv-helper-icon .bv-helper-icon-negative {
  bottom: 4px !important;
  right: -5px !important;
}

/* Terms checkbox */
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets input.bv-checkbox {
    height: 20px !important;
    width: 20px !important;
    border-radius: 0 !important;
}

/* Terms verbiage */
.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets.bv-fieldsets-actions .bv-fieldset .bv-checkbox-container label {
  font-family: theme('fontFamily.serif') !important;
  font-weight: theme('fontWeight.regular') !important;
  font-size: theme('fontSize.body.0') !important;
  line-height: theme('fontSize.body.1') !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-form-actions .bv-fieldset-casltext {
  font-family: theme('fontFamily.serif') !important;
  font-weight: theme('fontWeight.regular') !important;
  font-size: 10px !important;
  line-height: 1rem !important;
  padding: 0 !important;
  margin: 0 0 theme('spacing.s') 0 !important;
}

/* Submit button */
#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-form-actions-submit {
  font-family: theme('fontFamily.sans') !important;
  font-weight: theme('fontWeight.heavy') !important;
  font-size: theme('fontSize.button.0') !important;
  line-height: theme('fontSize.button.1') !important;
  border: 4px solid theme('colors.primary') !important;
  padding: 9px 20px !important;
  background: none !important;
  border-radius: 65px !important;
  color: theme('colors.black') !important;
  width: auto !important;
}

#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-form-actions-submit:hover,
#bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-form-actions-submit:active
{
  background: theme('colors.primary') !important;
  color: theme('colors.white') !important;
}

.bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets-actions {
    border-top: none !important;
    margin-top: theme('spacing.s') !important;
}

@media only screen and (min-width: 959px) {
  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review.bv-mbox-box
  {
      max-width: 1200px !important;
  }

  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox {
    padding-top: 33px !important;
    padding-right: 100px !important;
    padding-bottom: 75px !important;
  }

  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review.bv-mbox-narrow .bv-mbox {
    padding: 0 !important;
    margin-top: 50px !important
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-close
  {
      top: 10px !important;
      right: 12px !important;
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-sidebar {
    width: 30% !important;
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review.bv-mbox-wide .bv-mbox .bv-mbox-content-container {
    margin-left: 5.5% !important;
    margin-right: auto !important;
    width: 64% !important;
  }

  #bv-mboxzone-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-sidebar .bv-submission-sidebar.bv-submission-side
  {
      margin-left: 40px !important;
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-mbox-breadcrumb span.bv-mbox-breadcrumb-item span {
    font-size: theme('fontSize.s.0') !important;
    line-height: theme('fontSize.s.1') !important;
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-submission .bv-fieldsets .bv-fieldset .bv-fieldset-label .bv-fieldset-label-text {
    font-size: theme('fontSize.xs.0') !important;
    line-height: theme('fontSize.xs.1') !important;
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-radio-field .bv-fieldset-radio-wrapper {
    display: flex !important;
    align-items: center !important;
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-radio-field .bv-fieldset-radio-wrapper.bv-fieldset-isrecommended-wrapper
  {
    padding: theme('spacing.s') 0 0 0 !important;
  }

  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset-isrecommended .bv-fieldset-isrecommended-group {
    margin-top: 0 !important;
  }

  #bv-mboxzone-lightbox.bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset-rating .bv-fieldset-label {
    padding: 0 .75em .6em 0 !important;
  }

  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-rating-helper {
    position: relative !important;
    left: auto !important;
    top: auto !important;
  }

  /* Recommendation */
  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset.bv-fieldset-isrecommended .bv-fieldset-inner {
      clear: both !important;
  }

  /* Input fields */
  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-review-field-content-wrapper {
    max-width: 490px !important;
    clear: both !important;
  }

  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets textarea,
  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets input.bv-text {
    width: 490px !important;
    clear: both !important;
  }

  /* Radio buttons */
  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-isrecommended .bv-fieldset-isrecommended-group .bv-radio-container-li {
      width: auto !important;
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-isrecommended .bv-fieldset-isrecommended-group .bv-radio-container-li .bv-radio-wrapper-label
  {
      min-width: 144px !important;
  }

  /* Nickname */
  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset-usernickname.bv-fieldset-small {
    width: 100% !important;
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset-usernickname.bv-fieldset-small .bv-fieldset-inner {
    width: 50% !important;
  }

  /* User Location */
  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset-userlocation.bv-fieldset-small {
    width: 100% !important;
  }

  .bv-cv2-cleanslate .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-submission .bv-fieldsets .bv-fieldset-userlocation.bv-fieldset-small .bv-fieldset-inner {
    width: 50% !important;
  }

  /* User type */
  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-fieldset.bv-fieldset-contextdatavalue_WhichOfTheFollowingBestDescribesYou .bv-fieldset-select-wrapper {
    clear: both !important;
    max-width: 490px !important;
    margin-bottom: theme('spacing.s') !important;
  }

  #bv-mboxzone-lightbox .bv-mbox-lightbox .bv-mbox-content-submission-review .bv-mbox .bv-fieldsets .bv-select-field .bv-select
  {
      margin: 0 !important;
      position: relative !important;
  }
}
